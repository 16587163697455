import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Section from "../../common/section"
import Theme from "../../../theme/theme"
import GatsbyImage from "../../common/gatsby-image"
import Shape from "../../svg/shape"

const StyledSection = styled(Section)`
  position: relative;
  flex-direction: column;
  padding-top: 18rem;
  padding-bottom: 0 !important;
`

const IntroContent = styled.div`
  position: relative;
  padding-top: 11rem;
  padding-bottom: 5rem;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    padding-bottom: 15rem;
  }

  @media (min-width: ${props => props.theme.devices.desktopMedium}) {
    padding-bottom: 30rem;
  }

  .gatsby-image-wrapper {
    max-width: 80rem;
  }

  p {
    font-size: 4rem;
    line-height: 5rem;

    &:first-of-type {
      position: relative;
      font-size: 6rem;
      line-height: 7rem;
      font-family: "darker_grotesquebold", sans-serif;
      max-width: 80rem;
      margin-bottom: 4rem;

      @media (min-width: ${props => props.theme.devices.desktop}) {
        font-size: 15rem;
        line-height: 15rem;
        margin-bottom: 7rem;
      }

      @media (min-width: ${props => props.theme.devices.desktopLarge}) {
        font-size: 22rem;
        line-height: 22rem;
        margin-bottom: 10rem;
      }

      @supports (-webkit-text-stroke: 1px black) {
        color: transparent;
        -webkit-text-stroke-width: 0.1rem;
        -webkit-text-stroke-color: ${props => props.theme.colors.invertedText};
      }
    }

    @media (min-width: ${props => props.theme.devices.desktop}) {
      font-size: 6rem;
      line-height: 7rem;
    }

    &:nth-of-type(2) {
      max-width: 80rem;
      margin: 5rem 0;

      @media (min-width: ${props => props.theme.devices.desktop}) {
        margin: 5rem 0 5rem 30%;
      }

      @media (min-width: ${props => props.theme.devices.desktopMedium}) {
        margin: 20rem 0 20rem 30%;
      }

      @media (min-width: ${props => props.theme.devices.desktopLarge}) {
        margin: 36rem 0 36rem 30%;
      }
    }

    &:nth-of-type(3) {
      max-width: 100rem;

      @media (min-width: ${props => props.theme.devices.desktop}) {
        margin-left: 15rem;
      }
    }

    @media (min-width: ${props => props.theme.devices.desktopMedium}) {
      font-size: 8rem;
      line-height: 9rem;
    }

    @media (min-width: ${props => props.theme.devices.desktopLarge}) {
      font-size: 10rem;
      line-height: 12rem;
    }
  }
`

const StyledGatsbyImage1 = styled(GatsbyImage)`
  overflow: hidden;
  position: absolute;
  top: 15rem;
  right: 8vw;
  max-width: 42vw;
  z-index: -1;
  border-radius: 40rem 40rem 31rem 0;
  transform: translateZ(0);

  .gatsby-image-wrapper {
    background-color: ${props => props.theme.colors.third};
    transform: translateZ(0);
  }

  img {
    mix-blend-mode: multiply;
  }
`

const StyledGatsbyImage2 = styled(GatsbyImage)`
  overflow: hidden;
  z-index: -1;
  border-top-right-radius: 17rem;
  margin: 5rem 0;
  transform: translateZ(0);

  @media (min-width: ${props => props.theme.devices.desktop}) {
    position: absolute;
    top: 37%;
    left: -5rem;
    max-width: 28%;
    margin: 0;
  }
`

const StyledShape1 = styled(Shape)`
  position: absolute;
  top: 11rem;
  left: 10rem;
  transform: rotate(180deg);
  z-index: -1;
`

const StyledShape2 = styled(Shape)`
  position: absolute;
  top: 55%;
  left: -5rem;
  transform: rotate(90deg);
  z-index: -1;
`

const StyledShape3 = styled(Shape)`
  position: absolute;
  top: 63%;
  right: 30%;
  z-index: -1;
`

const StyledShape4 = styled(Shape)`
  position: absolute;
  bottom: 7rem;
  right: 44%;
  transform: rotate(-90deg);
  z-index: -1;
`

const StyledShape5 = styled(Shape)`
  position: absolute;
  top: 20rem;
  right: 20rem;
  width: 15rem;
  height: 15rem;
`

const StyledShape6 = styled(Shape)`
  position: absolute;
  top: 35%;
  left: 35rem;
  width: 10rem;
  height: 10rem;
`

const IntroSection = ({ aboutUsData }) => {
  return (
    <StyledSection
      pageColor={Theme.colors.third}
      textColor={Theme.colors.invertedText}
      invertedTextColor={Theme.colors.text}
      threshold={0.2}
    >
      <IntroContent>
        {documentToReactComponents(JSON.parse(aboutUsData.introText.raw))}
        {aboutUsData.introImages[0] && (
          <StyledGatsbyImage1
            image={aboutUsData.introImages[0].gatsbyImageData}
            isAnimated={true}
          />
        )}
        {aboutUsData.introImages[1] && (
          <StyledGatsbyImage2
            image={aboutUsData.introImages[1].gatsbyImageData}
            isAnimated={true}
          />
        )}
        <StyledShape1 isMainColor={true} />
        <StyledShape2 isMainColor={true} />
        <StyledShape3 isSecondColor={true} />
        <StyledShape4 isMainColor={true} />
        <StyledShape5 isSecondColor={true} type={8} />
        <StyledShape6 isMainColor={true} type={8} />
      </IntroContent>
    </StyledSection>
  )
}

IntroSection.propTypes = {
  aboutUsData: PropTypes.object,
}

export default IntroSection
