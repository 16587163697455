import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import GatsbyImage from "../../../common/gatsby-image"

const SingleMemberElement = styled.li`
  position: relative;
  flex-direction: column;
  background: #e3e3e3;
  margin-bottom: 5rem;
  padding: 2.8rem 2.8rem 7rem;
  @media (min-width: ${props => props.theme.devices.desktop}) {
    opacity: 0;
    transform: translate3d(0, 2rem, 0);
    transition:
      opacity 800ms ${props => props.theme.easing},
      transform 800ms ${props => props.theme.easing};
  }

  @media (min-width: ${props => props.theme.devices.desktop}) {
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: ${props => props.theme.devices.desktop}) {
    ${props =>
      props.inView &&
      `
    transition: opacity 800ms ${props.theme.easing} ${props.delay}ms, transform 800ms ${props.theme.easing} ${props.delay}ms;
    transform:translate3d(0, 0, 0);
    opacity: 1;
  `}
  }

  .gatsby-image-wrapper {
    overflow: hidden;
    border-top-right-radius: 10rem;
    transform: translateZ(0);
  }
`

const MemberName = styled.p`
  font-size: 3.6rem;
  line-height: 3rem;
  font-family: "darker_grotesquebold", sans-serif;
  margin-top: 7rem;
  margin-bottom: 0.5rem;
`

const MemberJob = styled.p`
  font-size: 1.8rem;
  line-height: 3rem;
  letter-spacing: 0.5rem;
  text-transform: uppercase;
`

const SingleMember = ({ member, inView = false, index = 0 }) => {
  return (
    <SingleMemberElement inView={inView} delay={index * 200}>
      <GatsbyImage image={member.image.gatsbyImageData} />
      <MemberName>{member.name},</MemberName>
      <MemberJob>{member.job}</MemberJob>
    </SingleMemberElement>
  )
}

SingleMember.propTypes = {
  member: PropTypes.object,
  inView: PropTypes.bool,
  index: PropTypes.number,
}

export default SingleMember
