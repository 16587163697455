import React from "react"
import Viewport from "../../components/common/viewport"
import AboutUsContent from "../content/about-us/about-us-content"
import SEO from "../seo"

const AboutUsPage = props => {
  const page = props.pageContext.aboutUsPage

  if (!page) return

  return (
    <Viewport>
      <AboutUsContent pageContext={props.pageContext} />
    </Viewport>
  )
}

export default AboutUsPage

export const Head = ({ location, pageContext }) => (
  <SEO
    pathname={location.pathname}
    title={pageContext.aboutUsPage.seoTitle}
    description={pageContext.aboutUsPage.seoDescription}
    keywords={pageContext.aboutUsPage.seoKeywords}
  />
)
