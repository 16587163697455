import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Section from "../../common/section"
import ClientsContainer from "../../common/clients/clients-container"
import Theme from "../../../theme/theme"

const StyledSection = styled(Section)`
  overflow: hidden;
  position: relative;
  flex-direction: column;
`

const KeyClientsSection = ({ header, projects, projectsSlug }) => {
  return (
    <StyledSection
      pageColor={Theme.colors.invertedText}
      textColor={Theme.colors.text}
      threshold={0.1}
      header={header}
    >
      <ClientsContainer projects={projects} projectsSlug={projectsSlug} />
    </StyledSection>
  )
}

KeyClientsSection.propTypes = {
  projects: PropTypes.array,
  header: PropTypes.string,
  projectsSlug: PropTypes.string,
}

export default KeyClientsSection
