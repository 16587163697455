import React from "react"
import styled from "@emotion/styled"
import { useInView } from "react-cool-inview"
import PropTypes from "prop-types"
import SingleMember from "./single-member"

const TeamListElement = styled.ul`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  z-index: 1;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 10rem;
  }
`

const TeamList = ({ team = [] }) => {
  const { observe, inView } = useInView({ threshold: 0.3 })

  if (!team) return

  return (
    <TeamListElement ref={observe}>
      {team.map((member, index) => (
        <SingleMember
          key={member.contentful_id}
          member={member}
          inView={inView}
          index={index}
        />
      ))}
    </TeamListElement>
  )
}

TeamList.propTypes = {
  team: PropTypes.array,
}

export default TeamList
