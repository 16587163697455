import React from "react"
import IntroSection from "./intro-section"
import ClientsSection from "./clients-section"
import KeyClientsSection from "./key-clients-section"
import OurTeamSection from "./our-team-section"
import OurTeamDescSection from "./our-team-desc-section"
import ContactSection from "../../common/contact/contact-section"

const AboutUsContent = ({ pageContext }) => {
  const aboutUsData = pageContext.aboutUsPage

  if (!aboutUsData) return null

  return (
    <>
      <IntroSection aboutUsData={aboutUsData} />
      {aboutUsData.clientsSlider.length > 0 && (
        <ClientsSection
          header={aboutUsData.clientsTitle}
          clientsSlide={aboutUsData.clientsSlider}
        />
      )}
      <KeyClientsSection
        header={aboutUsData.keyClientsTitle}
        projects={pageContext.projects}
        projectsSlug={pageContext.projectsSlug}
      />
      <OurTeamDescSection aboutUsData={aboutUsData} />
      <OurTeamSection
        team={aboutUsData.ourTeam}
        firstColumnDesc={aboutUsData.ourTeamFirstColumn}
        secondColumnDesc={aboutUsData.ourTeamSecondColumn}
      />
      <ContactSection
        content={aboutUsData.contact}
        contactPageSlug={pageContext.contactPageSlug}
      />
    </>
  )
}

export default AboutUsContent
