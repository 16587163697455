import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import GatsbyImage from "../gatsby-image"
import Link from "../link"

const ClientsContainerElement = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  padding-top: 5rem;
`

const SingleClient = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  max-width: 100%;
  flex: 1;

  > div {
    border: 0.5rem solid #fff;
  }
`

const StyledGatsbyImage = styled(GatsbyImage)`
  display: inline-flex;
  justify-content: center;
  max-width: 100%;
  max-height: 5rem;
  margin-bottom: 7rem;

  @media (min-width: ${props => props.theme.devices.tablet}) {
    max-height: 4rem;
  }

  img {
    height: 4rem;
  }

  @media (min-width: ${props => props.theme.devices.desktop}) {
    max-height: 45rem;

    img {
      height: 5rem;
    }
  }

  .gatsby-image-wrapper {
    max-width: 100%;
    width: 100%;
  }
`

const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`

const ClientsContainer = ({ projects, projectsSlug }) => {
  return (
    <ClientsContainerElement>
      {projects.slice(0, 10).map(
        (project, index) =>
          project?.node.client?.clientLogo && (
            <SingleClient key={project.node.id} className="bg-img">
              <StyledGatsbyImage
                image={project.node.client.clientLogo.gatsbyImageData}
                isLogo={true}
              />
              <StyledLink
                url={`/${projectsSlug}/${project.node.slug}`}
                ariaLabel={project.node.client.clientName}
              />
            </SingleClient>
          ),
      )}
    </ClientsContainerElement>
  )
}

ClientsContainer.propTypes = {
  projects: PropTypes.array,
}

export default ClientsContainer
