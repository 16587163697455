import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Section from "../../common/section"
import Shape from "../../svg/shape"
import TeamList from "./our-team/team-list"
import Theme from "../../../theme/theme"

const StyledSection = styled(Section)`
  position: relative;
  flex-direction: column;
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
  margin-bottom: 0;
  background-color: ${props => props.theme.colors.gray2};

  @media (min-width: ${props => props.theme.devices.desktop}) {
    padding-top: 25rem !important;
    padding-bottom: 15rem !important;
  }
`

const StyledShape = styled(Shape)`
  position: absolute;
  top: 25rem;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  z-index: 0;
`

const TeamDescription = styled.div`
  position: relative;
  margin-bottom: 10rem;
  z-index: 1;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 8rem;
    margin-bottom: 25rem;
  }
`

const DescriptionColumn = styled.div`
  font-size: 2.6rem;
  line-height: 4rem;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    font-size: 3.6rem;
    line-height: 6rem;
  }
`

const OurTeam = ({ firstColumnDesc, secondColumnDesc, team = [] }) => {
  return (
    <StyledSection
      pageColor={Theme.colors.invertedText}
      textColor={Theme.colors.text}
      threshold={0.5}
    >
      <TeamDescription>
        <DescriptionColumn>
          {documentToReactComponents(JSON.parse(firstColumnDesc.raw))}
        </DescriptionColumn>
        <DescriptionColumn>
          {documentToReactComponents(JSON.parse(secondColumnDesc.raw))}
        </DescriptionColumn>
      </TeamDescription>
      <TeamList team={team} />
      <StyledShape type={7} isSecondColor={true} animationTime={1000} />
    </StyledSection>
  )
}

OurTeam.propTypes = {
  firstColumnDesc: PropTypes.object,
  secondColumnDesc: PropTypes.object,
  team: PropTypes.array,
}

export default OurTeam
