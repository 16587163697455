import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import Section from "../../common/section"
import GatsbyImage from "../../common/gatsby-image"
import Arrow from "../../svg/arrow"
import Theme from "../../../theme/theme"
import { useInView } from "react-cool-inview"

const StyledSection = styled(Section)`
  position: relative;
  flex-direction: column;
  margin-bottom: 0;
  z-index: 2;
`

const OurTeamIntro = styled.div`
  font-size: 2.8rem;
  line-height: 4rem;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    max-width: 48%;
  }

  @media (min-width: ${props => props.theme.devices.desktopMedium}) {
    font-size: 5.8rem;
    line-height: 6rem;
  }

  @media (min-width: ${props => props.theme.devices.desktopLarge}) {
    font-size: 6.8rem;
    line-height: 8rem;
  }
`

const OurTeamContent = styled.div`
  position: relative;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-column-gap: 18rem;

    .image-5 {
      display: block;
      position: absolute;
      right: 6rem;
      top: -8rem;
      z-index: -1;
    }

    .image-6 {
      display: block;
      position: absolute;
      right: -4rem;
      top: 56%;
      z-index: -1;
    }
  }

  .image-5,
  .image-6 {
    display: none;
  }
`

const OurTeamImage = styled.div`
  position: relative;
  padding-top: 27rem;

  @media (max-width: ${props => props.theme.devices.desktop}) {
    margin: 3rem 0 5rem;

    > div {
      &.image-1,
      &.image-2,
      &.image-3,
      &.image-4 {
        display: none;
      }
    }
  }

  > div {
    position: absolute;

    &.image-0 {
      overflow: hidden;
      bottom: 0;
      left: 0;
      border-top-right-radius: 36rem;
      transform: translateZ(0);

      @media (min-width: ${props => props.theme.devices.desktop}) {
        left: -5rem;
      }
    }

    &.image-1 {
      top: 4rem;
      max-width: 50%;
    }

    &.image-2 {
      right: -2rem;
      top: 28rem;
      max-width: 30%;
    }

    &.image-3 {
      right: 40%;
      top: 57%;
      max-width: 30%;
    }

    &.image-4 {
      right: -6rem;
      bottom: 0;
      max-width: 30%;
      transform: translate3d(0, 50%, 0);
    }
  }
`

const OurTeamDescription = styled.div`
  padding-bottom: 5rem;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    padding-bottom: 15rem;
  }

  ul {
    font-size: 3rem;
    line-height: 7rem;
    font-family: "darker_grotesquebold", sans-serif;
    list-style-type: none;

    @media (min-width: ${props => props.theme.devices.desktop}) {
      font-size: 4rem;
      line-height: 10rem;
    }

    @media (min-width: ${props => props.theme.devices.desktop}) {
      li {
        ${props =>
          props.inView &&
          ` 
        top: 0;
        opacity: 1;
      `}
      }
    }

    @media (min-width: ${props => props.theme.devices.desktop}) {
      font-size: 5rem;
      line-height: 14rem;
    }

    @media (min-width: ${props => props.theme.devices.desktopLarge}) {
      font-size: 6.8rem;
      line-height: 16rem;
    }
  }
`

const SingleItemList = styled.li`
  position: relative;
  padding-left: 4.3rem;
  @media (min-width: ${props => props.theme.devices.desktop}) {
    top: 1rem;
    opacity: 0;
    transition:
      opacity 800ms ${props => props.theme.easing}
        ${props => props.index * 200}ms,
      top 800ms ${props => props.theme.easing} ${props => props.index * 200}ms;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 0;
    width: 2.6rem;
    margin-top: 1rem;
    transform: translate3d(0, -50%, 0) rotate(90deg);

    path {
      fill: ${props => props.theme.colors.main};
    }
  }
`

const options = {
  renderNode: {
    [BLOCKS.UL_LIST]: (node, children) => {
      if (!children) return

      return (
        <ul>
          {children.map((child, index) => (
            <SingleItemList key={index} index={index}>
              <Arrow />
              {child.props.children}
            </SingleItemList>
          ))}
        </ul>
      )
    },
  },
}

const OurTeamDesc = ({ aboutUsData }) => {
  const { observe, inView } = useInView({
    threshold: 0.2,
    unobserveOnEnter: true,
  })

  return (
    <StyledSection
      pageColor={Theme.colors.invertedText}
      textColor={Theme.colors.text}
      header={aboutUsData.ourTeamTitle}
    >
      <OurTeamIntro>
        {documentToReactComponents(
          JSON.parse(aboutUsData.ourTeamDescription.raw),
        )}
      </OurTeamIntro>
      <OurTeamContent>
        {aboutUsData.ourTeamImages && (
          <OurTeamImage>
            {aboutUsData.ourTeamImages.slice(0, 4).map((image, index) => (
              <GatsbyImage
                image={image.gatsbyImageData}
                extraClass={`image-${index}`}
                key={index}
                isAnimated={true}
              />
            ))}
          </OurTeamImage>
        )}
        <OurTeamDescription ref={observe} inView={inView}>
          {documentToReactComponents(
            JSON.parse(aboutUsData.ourTeamSecondDescription.raw),
            options,
          )}
        </OurTeamDescription>

        <GatsbyImage
          image={aboutUsData.ourTeamImages[5].gatsbyImageData}
          extraClass={`image-5`}
          isAnimated={true}
        />
        <GatsbyImage
          image={aboutUsData.ourTeamImages[6].gatsbyImageData}
          extraClass={`image-6`}
          isAnimated={true}
        />
      </OurTeamContent>
    </StyledSection>
  )
}

OurTeamDesc.propTypes = {
  aboutUsData: PropTypes.object,
}

export default OurTeamDesc
