import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import Section from "../../common/section"
import Shape from "../../svg/shape"
import ContentSlider from "../../common/content-slider"
import Theme from "../../../theme/theme"

const StyledSection = styled(Section)`
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 5rem;

  @media (min-width: ${props => props.theme.devices.desktop}) {
    margin-bottom: 15rem;
  }

  @media (min-width: ${props => props.theme.devices.desktopMedium}) {
    margin-bottom: 25rem;
  }

  h2 {
    max-width: 62rem;
    margin-bottom: 6rem;

    @media (min-width: ${props => props.theme.devices.desktopMedium}) {
      margin-bottom: 10rem;
    }

    @media (min-width: ${props => props.theme.devices.desktopLarge}) {
      margin-bottom: 13rem;
    }
  }
`

const StyledShape = styled(Shape)`
  position: absolute;
  top: -10rem;
  right: -70rem;
  transform: rotate(-180deg);
  z-index: -1;
`

const ClientsSection = ({ header, clientsSlide }) => {
  return (
    <StyledSection
      pageColor={Theme.colors.invertedText}
      textColor={Theme.colors.text}
      invertedTextColor={Theme.colors.invertedText}
      threshold={0.1}
      header={header}
    >
      <ContentSlider
        items={clientsSlide}
        isClients={true}
        isNavigationLeft={true}
      />
      <StyledShape type={6} isSecondColor={true} />
    </StyledSection>
  )
}

ClientsSection.propTypes = {
  clientsSlide: PropTypes.array,
  header: PropTypes.string,
}

export default ClientsSection
